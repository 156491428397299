
.header {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px 2px, rgba(0, 0, 0, 0.3) 0px 1px 3px 2px;
    z-index: 30;
    background: #000000;
    color: #FFFFFF;

}

.header__top{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
    background: #000000;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px;

}
.header__top__item__link{
    color: #FFFFFF;
    cursor: pointer;
    transition: all ease.5s;
}
.header__top__item__btn{
    cursor: pointer;
}
.header__top__item{
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 33%;
    justify-content: center;
}
.header__top__item__btn{
    display: flex;
    align-items: center;
    column-gap: 5px;
    transition: all ease.5s;
}
.header__top__item__icon{
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
    transition: all ease.5s;
}
.header__top__item__icon:hover,
.header__top__item__btn:hover,
.header__top__item__btn:hover .header__top__item__icon ,
.header__top__item__link:hover{
    color: #EC5E5A;
}
@media screen and (max-width: 1250px){
    .header__top__item{
        display: none;
    }
    .header__top__item__mobile{
        display: flex;
        width: 100%;
    }
}
.header__container{
    position: relative;
    width: 100%;
    padding: 0 20px;
    margin-top: 35px;
}
@media screen and (max-width: 1100px) {
    .header__container{
        padding: 0 0;
    }
}
.header__mobile{
    width: 100%;
    padding: 0 5px;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    display: none;
}
.header__mobile__item{
    display: flex;
    align-items: center;

    font-size: 35px;
    column-gap: 10px;
}
.nav__menu__controller__link{
    color: #FFFFFF;
    display: flex;
    align-items: center;
    position: relative;
}

.nav__menu__controller__close-icon{
    font-size: 40px;
}
.header__container__row {
    display: flex;
    align-items: center;
    padding-top: 5px;
}
.header__container__row__item{

    display: flex;
    justify-content: center;
}
.header__container__row__item:nth-child(3){
    width: 10%;
}
.header__container__row__item:nth-child(2),
.header__container__row__item:nth-child(4){
    width: 44%;
}
.header__container__row__item:nth-child(4){
    justify-content: end;
}
.header__logo__box {
    max-width: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.header__logo {
    width: 100%;
    height: auto;
}
.header__logo-mobile{
    width: 70px;
    height: auto;
}
.nav {
    align-items: center;
    display: flex;
    justify-content: center;

}

.nav__menu {
    width: 100%;
    display: flex;
    column-gap: 30px;
    align-items: center;

}
.nav__menu__right__block{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.nav__menu__item {
    text-transform: uppercase;
    cursor: pointer;
}

.nav__menu__link {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    transition: all ease .5s;
}
@media screen and (max-width: 1570px){
    .nav__menu{
        column-gap: 15px;
    }
}
@media screen and (max-width: 1280px){
    .nav__menu__link{
        font-size: 14px;
    }
}
.nav__menu__link.active {
    color: #EC5E5A;
    text-decoration: underline;
}

.nav__menu__link:hover {
    color: #EC5E5A;
    text-decoration: underline;
}

.nav__menu__controller {
    display: flex;
    column-gap: 40px;
    font-size: 20px;

}
.nav__menu__controller__change_language{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.nav__menu__controller__change_language__item{
    font-size: 20px;
    cursor: pointer;
    color: #FFFFFF;
}
@media screen and (max-width: 1280px){
    .nav__menu__controller__change_language__item{
        font-size: 14px;
    }
}
.nav__menu__controller__change_language__item.active{
    color: #EC5E5A;
}
.nav__menu__controller__change_language__item:hover{
    color: #EC5E5A;
}
.nav__menu__controller__item {
    display: flex;
    align-items: center;

}

.nav__menu__controller__icon {
    transition: all ease .1s;
    cursor: pointer;
    color: #ffffff;
    fill: #FFFFFF;
    width:30px;
    height:30px;
}
.nav__menu__controller__icon:hover{
    fill: #EC5E5A;
}
.nav__menu__controller__icon:hover path{
    fill: #EC5E5A;
}
.nav__menu__controller__icon path{
    transition: all ease .5s;
    fill: #FFFFFF;
}
.header__mobile.active .nav__menu__controller__icon{
    color: black;
}.header__mobile.active .cart__count{
    color: black;
}

.nav__menu__controller__icon.active {
    fill: #EC5E5A;
}

/*.nav__menu__controller__icon:hover {*/
/*    fill: #EC5E5A;*/
/*    color: #EC5E5A;*/
/*}*/
.nav__menu__controller__icon path:hover {
    fill: #EC5E5A;
}
.nav__menu__controller__icon-custom {
    stroke-width: 3px;
}

.nav__menu__controller__icon-custom path {
    fill: transparent;
}

.cart__count {
    background: #EC5E5A;
    position: absolute;
    bottom: -3px;
    right: -3px;
    font-size: 13px;
    width: 13px;
    height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    z-index: 1;
}

.nav__menu__controller__item:hover .cart__count {
    background: #EC5E5A;
    color: #FFFFFF;
}

.header__burger{
    position: fixed;
    bottom: 0;
    top: 0;
    background: #FFFFFF;
    left: 0;
    right: 0;
    z-index:-1;
    padding: 100px 0;
    width: 100%;
}
.header__burger__menu__container{
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    width: 100%;
    justify-content: space-between;
}
.header__burger__menu{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 95%;
    padding-left: 10px;
}
.header__burger__menu__item{

    display: flex;
    align-items: center;
    font-size: 30px;
    width: 100%;
    justify-content: space-between;
}
.header__burger__menu__link{
    text-decoration: none;
    color: black;
    font-size: 40px;
}
.header__burger__social__link__icon{
    color: black;
    font-size: 25px;
}
.header__burger__social__link{
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 10px;

     padding-bottom: 20px;
    color: black;
}
.header__burger__menu__item__icon{
    color: black;
}
.header__burger__controller__item{
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 25px;
}
.header__burger__controller__item__city{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
@media screen and (max-width: 1100px) {
    .header__container__row__item {
        display: none;
    }
    .header__mobile{
        display: flex;
    }
    .header{
        padding: 0;
    }
}
.nav__menu__item__shop:hover .header__hover__categories{
    display: block;
}
.header__hover__categories:hover{
    display: block;
}
.header__hover__categories{

    position: absolute;
    z-index: 30;
    width: 100vw;
    overflow-y: auto;
    max-height: 500px;
    left: 0;
    padding-top: 45px;
    display: none;
}
@media screen and (max-width: 1024px){
  .header__hover__categories{
      display: none;
  }
}
.header__hover__categories::-webkit-scrollbar {
    display: none;
}
.header__hover__categories__container{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    background: #FFFFFF;
}

.header__hover__categories-side{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    font-size: 20px;

}
.header__hover__categories__left-side{


    padding: 30px 40px 30px 0;
}
.header__hover__categories__right-side{
    padding: 30px 0 30px 40px;

}
.header__hover__categories-side-item{
    transition: all ease .5s;
    cursor: pointer;
    color: black;
}
.header__hover__categories-side-item:hover{
    color: #EC5E5A;
}
.header__hover__categories-side-item:hover{
    color: #EC5E5A;
}
.nav__menu__controller__item__city{
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 5px;
}