@import "reset-css";

@font-face {
    font-family: 'Euclid Circular A Regular';
    src: url('./assets/fonts/Euclid Circular A/Euclid Circular A Regular.ttf') format('truetype');

    font-style: normal;
}
@font-face {
    font-family: 'Euclid Circular A Medium';
    src: url('./assets/fonts/Euclid Circular A/Euclid Circular A Medium.ttf') format('truetype');

    font-style: normal;
}
@font-face {
    font-family: 'Euclid Circular A Light';
    src: url('./assets/fonts/Euclid Circular A/Euclid Circular A Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;

}
strong {
    font-weight: bold;
}

em {
    font-style: italic;
}
input {
    padding: 0;
    background: #FFFFFF;
    border: none;
}

textarea {
    background: #FFFFFF;
    border: none;
    resize: none;
}

input:focus {
    outline: none;

}

textarea:focus {
    outline: none;

}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

a {
    text-decoration: none;
}


body {
    overflow-x: hidden;
    background-size: contain;
    background: #FFFFFF url("./assets/images/flowers_pattern_white bg.png") fixed;
    font-family: 'Euclid Circular A Regular', sans-serif!important;
    position: relative;
}

p {
    font-family: 'Euclid Circular A Light', sans-serif!important;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.main{
    padding: 0px 0 80px 0;
}
@media screen and (max-width: 1280px) {
    .main{
    }
}
@media screen and (max-width: 1100px) {
}
.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 10px;

}

svg {

}

.main__title {
    font-size: 30px;
    text-align: left;
    font-weight: 400;
    width: 95%;
    padding-top: 30px;
}

@media screen and (max-width: 600px) {
    .main__title {
        font-size: 25px;
    }

    .container {
        padding: 0 5px;
    }
}

.main-btn {
    background: #EC5E5A;
    color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EC5E5A;
    transition: all ease .5s;
    cursor: pointer;

}

.main-btn-link {
    color: #FFFFFF;
    text-decoration: none;

}

.main-btn:hover {
    background: #FFFFFF;
    color: black;

}

.main-btn:hover .main-btn-link {
    color: black;
}

.swiper-pagination-bullet {
    width: 14px !important;
    height: 14px !important;
}

.swiper-pagination-bullet-active {
    background: #EC5E5A !important;
}


.liked path {
    fill: #EC5E5A!important;
}

.page__navigation {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    width: 95%;
    justify-content: start;
    margin:  20px auto;
    gap: 5px;
}
.page__navigation svg{
    width: 20px;
    height: 20px;
}

.page__navigation__link {
    background: #FFFFFF;
    font-size: 20px;
    border-radius: 15px;
    transition: all ease .5s;
    cursor: pointer;
    color: black;
    text-decoration: underline;
}

.page__navigation__link:nth-child(3){
    font-weight:500;
    font-family: 'Euclid Circular A Medium', sans-serif!important;
}
.page__navigation__link:hover {
    color: #EC5E5A;
}
@media screen and (max-width: 600px) {
    .page__navigation__link{
        font-size: 14px;
    }
}