
.faq__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq__row {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    transition: all ease .5s;
    padding-top: 30px;

}

.faq__title {
   text-align: center;
}

.faq__row__item__title {
    display: flex;
    align-items: center;
    font-size: 30px;
    transition: all ease .5s;
    font-style: italic;
    font-weight: 300;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
    justify-content: space-between;
}

.faq__row__item__title:hover {
    color: #EC5E5A;
    border-bottom: 1px solid #EC5E5A;

}

.faq__row__item_arrow {

}

.faq__row__item_arrow.active {
    transform: rotateX(180deg);
}

.faq__row__item {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
}

.faq__row__item__dropdown {
    padding: 20px 0 0 40px;
    transition: all ease .5s;
    font-size: 35px;
    line-height: 37px;
    letter-spacing: 1px;
}

@media screen and (max-width: 600px) {
    .faq__row__item__dropdown {
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 1px;
        padding: 10px 0;
    }

    .faq__row__item__title {
        font-size: 20px;
    }
}