
.reviews__page{
    padding-bottom: 50px;
}
.reviews__row__masonry{
    padding-top: 50px;
}
.reviews__page__title{
    text-align: center;
    font-size: 50px;
}
.reviews__row__masonry__card{
    background: #EC5E5A;
    height: auto;
    min-height: 300px;
}
.reviews__row__masonry__card-comment{
    font-size: 20px;
    word-break: break-all;
    line-height: 25px;
}
.reviews__row__masonry__card__read__more{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}
.reviews__row__masonry__card__read__more__icon{
    margin-top: 3px;
    font-size: 20px;
}