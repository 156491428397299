.quick__dial {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 20;
}

.quick__dial__container {
    display: flex;
    align-items: center;
    transition: all ease .5s;
    column-gap: 10px;
}


.quick__dial__icon {
    font-size: 50px;
    background: #EC5E5A;
    border-radius: 50%;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
    transition: all ease .5s;
    transform: translateX(0);
    opacity: 1;
}

.quick__dial__icon.hide {
    transform: translateX(2000px);
    opacity: 0;
}

.quick__dial__slide__form {
    display: flex;
    align-items: end;
    column-gap: 20px;
    transition: all ease .5s;
    transform: translateX(2000px);
    opacity: 0;
}

.quick__dial__slide__form.show {
    transform: translateX(100px);
    opacity: 1;
}

.quick__dial__contact__form__close__icon {
    font-size: 40px;
    background: #EC5E5A;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: all ease .5s;
    color: #FFFFFF;
}



.quick__dial__contact__form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    transition: all ease .5s;
    align-items: center;
    width: 300px;
    background: #EC5E5A;
    color: #FFFFFF;
    border-radius: 15px;
    padding:15px 5px;
}

.quick__dial__contact__form__text {
    text-align: center;
    font-size: 18px;
}

.quick__dial__contact__form__logo {
    width: 100px;
    background: #EC5E5A;
    margin-top: -50px;
    height: auto;
    border-radius: 15px;
}

.quick__dial__contact__form__input__box {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 0 5px;
    border: 1px solid #EC5E5A;
}

.quick__dial__contact__form__input {
    height: 30px;
    text-indent: 10px;
    font-size: 15px;
    border: none;
    border-radius: 15px;

}

.quick__dial__contact__form__input:focus {
    outline: none;
}
.quick__dial__contact__form__submit-btn{
    cursor: pointer;
}
.quick__dial__contact__form__submit-btn__icon {
    color: #EC5E5A;
    font-size: 30px;
}
.quick__dial__icon__mobile__box{
    display: none;
}
@media screen and (max-width: 1100px) {
    .quick__dial {
        bottom: 100px;
    }
}
@media screen and (max-width: 800px) {
    .quick__dial__slide__form {
       display: none;
    }
    .quick__dial__icon__desktop{
        display: none;
    }
    .quick__dial__icon__mobile__box{
        display: block;
    }
    .quick__dial__icon{
        font-size: 50px;
    }
}