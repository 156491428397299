

.not_found_page{
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;

}
.not_found_page__row{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.not_found_page__container{
    max-width: 500px;
}
.not_found_page_title{
    font-size: 100px;
    text-align: center;
}
.not_found_page_sub_title{
    font-size: 20px;
    text-align: center;
}
.not_found_page__btn{
   height: 40px;
}