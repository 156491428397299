.search{
    width: 100vw;
    padding: 20px 0;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
}
.search__container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    row-gap: 20px;

}
.search__input__box__icon{
    position: absolute;
    transition: all ease .5s;
}
.search__input__box__icon:hover{
    fill: #EC5E5A;
}
.search__input__box__search__icon{
    top: 8px;
    left: 0;
}
.search__input__box__search__btn__icon{
    transform: rotateY(180deg);
    top:13px;
    right: 0;
    cursor: pointer;
}
.search__input__box{
    max-width: 500px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000000;
    justify-content: center;
}
.search__input__box__icon{
    font-size: 30px;
    stroke-width: .1px;
}
.search__input{
    border: none;
    width: 80%;
    height: 45px;
    font-size: 20px;
    color: #0a0a0a;
}
.search__input:focus{

    outline: none;
}
.search__close__btn{
    position: absolute;
    transform: translateY(-50%);
    top: 30px;
    right: 150px;
    font-size: 25px;
    color: #0a0a0a;
    cursor: pointer;
    transition: all ease .5s;
}
.search__close__btn:hover{
    color: #EC5E5A;
}
@media screen and (max-width: 1100px){
    .search__container{
        width: 90%;
        margin: 0 auto;
    }
    .search__close__btn{
        position: relative;
        top: 0;
        right: 0;
       margin-left: auto;
    }
    .search__input__box{
        width: 100%;
        max-width: none;
    }
    .search__container{
        flex-direction: column;
        row-gap: 25px;
    }
}
.search__result__row{
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    row-gap:20px
}
.search__result__row__item{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
    transition: all ease .5s;

}
.search__result__row__item:hover{
    color: #EC5E5A;
    border-bottom: 1px solid #EC5E5A;
}
.search__result__row__item__img__box{
    width: 100px;
    height: 50px;
}
.search__result__row__item__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.search__result__row__item__name{
    width: 25%;
}
.search__result__row__item__price{
    width: 25%;
}
.search__result__row__item_icon{
    width: 10%;

    font-size: 20px;
}
.search__result__row__item_link{
    color: black;
    text-decoration: none;
}
.search__result__row__message{
text-align: center;
    font-size: 20px;
}