.why-us_card {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.why-us_title{
    font-size: 15px;
    line-height: 20px;
    color: #1c1c1c;
    text-align: center;
}
@media screen and (max-width: 500px) {
    .why-us_title {
        font-size: 13px;
    }
}
