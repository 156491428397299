.storefront {
    padding-top: 40px;
}


.storefront__container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

@media screen and (max-width: 700px) {
    .storefront__container {
        row-gap: 0;
    }

    .storefront {
        padding-top: 30px;
    }
}

.storefront__swiper {
    padding: 30px 5px 0 !important;
}

.storefront__swiper__slide {
    width: 284px;
    height: auto;
    display: flex;
}
.product__page_tags{
position: absolute;
  top: 10px;
  display: flex;
  gap: 10px;
  left: 174px;
}
.storefront__row {
    width: 100%;
    padding-top: 10px;
}

.storefront__card {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    row-gap: 10px;
    border: 2px solid #f7f5f7;
    cursor: pointer;
    transition: all ease 1s;
    min-width: 120px;
    position: relative;
    background: #FFFFFF;
}
.storefront__card:hover{
    border: 2px solid #EC5E5A;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.storefront__card__discount {
    position: absolute;
    top: -28px;
    left: 10px;
    background: #EC5E5A;
    color: #FFFFFF;
    font-size: 20px;
    padding: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

@media screen and (max-width: 900px) {
    .storefront__card__discount {
        height: 20px;
        width: 20px;
        top: -14px;
        left: -8px;
        font-size: 16px;
    }
}


.storefront__card__img-box {
    width: 100%;
    background: #FFFFFF;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 382px;
}
.storefront__card:hover .storefront__card__detail{
    transform: translateY(0px);
}
.storefront__card__detail{
    font-size: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #FFFFFF;
    background: rgba(236, 94, 90, 0.77);
    transition:  all ease 0.5s;
    height: 40px;
    transform: translateY(50px);
}
.storefront__card__info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: #000000;
}
.storefront__card__increase__change {
    display: flex;
    column-gap: 10px;
    height: 40px;
}

.storefront__card__increase__change__item {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;
    transition: all ease .5s;
    padding: 0 3px;
    background: #EC5E5A;
    border-radius: 5px;
}

.storefront__card__increase__change__item.active {
    border: 1px solid #EC5E5A;
    background: #FFFFFF;
    color: black;
}

.storefront__card__increase__change__item:hover {
    border: 1px solid #EC5E5A;
}

.storefront__card__name {
    font-size: 30px;
    font-weight: 400;
}

.storefront__card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.storefront__card__price-box {
    display: flex;
    column-gap: 10px;
    font-size: 20px;
    align-items: center;
    color: #000000;
}

.storefront__card__price-box:has(.storefront__card__discount_price) .storefront__card__current_price {
    text-decoration: line-through;
    color: #666;
}

.storefront__card__price-box:has(.storefront__card__discount_price) .storefront__card__current_price {
    font-size: 20px;
}

.storefront__header{
    display: flex;
    width: 95%;
    justify-content: space-between;
}
.storefront__title{
    font-size: 30px;
    font-weight: 400;
}
.storefront__btn {
    display: flex;
    align-items: center;

    transition: all ease .5s;
}

.storefront__btn:hover {
    color: #EC5E5A;
}

.storefront__btn svg {
    font-size: 25px;
    margin-top: 3px;
}


.storefront__btn__link {
    cursor: pointer;
    font-size: 20px;
    color: black;
    transition:  all ease 0.3s;
}
.storefront__btn__link:hover{
    color: #EC5E5A;
}
@media screen and (max-width: 1100px) {
    .product__page_tags {
        top: 40px;
        display: flex;
        gap: 7px;
        right: 0px;
        flex-direction: column;
        z-index: 9;
        left: auto;
    }
}
@media screen and (max-width: 600px){
    .storefront__btn__link{
        font-size: 15px;
    }
    .storefront__title{
        font-size: 25px;
    }
    .storefront__card__price-box:has(.storefront__card__discount_price) .storefront__card__current_price {
    font-size: 13px;
}
}
.storefront__card__hidden {
    position: absolute;
    top: 0;
    height: 265px;
    right: 0;
    left: 0;
    background: rgba(236, 94, 90, 0.2);
    width: 100%;
    opacity: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .storefront__card__img-box {
        height: 250px;
    }

    .storefront__card__hidden {
        height: 200px;
    }
}

.storefront__card:hover .storefront__card__hidden {
    z-index: 2;
    opacity: 1;
}

.storefront__card__hidden__option {
    display: flex;
    column-gap: 20px;
    transition: all ease .3s;
    transform: translateY(20px);
}

.storefront__card__hidden__option-icon {
    font-size: 40px;
    transition: all ease .4s;
    color: black;
    width: 40px;
    height: 40px;

}

.storefront__card__hidden__option-icon.like {
    color: #EC5E5A;
}

.storefront__card:hover .storefront__card__hidden .storefront__card__hidden__option {
    transform: translateY(0);

}

.storefront__card__hidden__option-icon-box {
    background: #FFFFFF;
    padding: 8px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;;
}

.storefront__card__hidden__option-icon-box:hover {
    border: 2px solid #EC5E5A;
    color: #EC5E5A;
}

.storefront__card__option {
    width: 100%;
    justify-content: center;
    display: flex;
    gap:10px;
}

.storefront__card__option__mobile__icon {
    font-size: 30px;
    color: black;
    width: 25px;
    height: 25px;
    transition: .3s;
}
.storefront__card__option__mobile__icon:hover {
    color: #EC5E5A;
}
.tag_r{
    background-color: #67C358BF;
    padding: 4px;
    color: white;
}
@media screen and (max-width: 1100px) {
    .storefront__card__hidden {
        display: none;
    }

    .storefront__card__option__mobile {
        display: flex;
    }
    .storefront__swiper__slide{
        width: 45%;
    }
}

@media screen and (max-width: 600px) {
    .storefront__card__option__mobile__icon {
    width: 25px;
    }
    .storefront__card__discount_price {
        font-size: 16px;
    }

    .storefront__card {
        width: auto;
        height: auto;
    }


    .storefront__card__price-box {
        font-size: 16px;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 400px) {
    .storefront__card__option__mobile__icon {
        font-size: 30px;
    }

    .storefront__container {
        row-gap: 0;
    }
}
.storefront__card__rating {
    display: flex;
    column-gap: 5px;
    height: 20px;
}
.storefront__card__rating svg{
    width: 20px;
    height: 20px;
}
