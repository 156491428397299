
.seoBlock {
    padding: 40px;
}

.seoBlock__row {
    display: flex;
    column-gap: 50px;
    justify-content: center;
}

.seoBlock__img__box {
    max-width: 500px;
    width: 100%;
    height: auto;
}

.seoBlock__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.seoBlock__content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.seoBlock__title {
    font-size: 30px;
}

.seoBlock__text {
    max-width: 1100px;
    font-size: 18px;
}

@media screen and (max-width: 600px) {
    .seoBlock__row {
        flex-direction: column;
        row-gap: 20px;
    }
    .seoBlock__text {
        max-width: 600px;
    }
}
.seoBlock__text {
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.seoBlock__text--collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Number of lines to show */
    -webkit-box-orient: vertical;
    max-height: 4.5em; /* Adjust height based on the font size and line clamp */
    overflow: hidden;
    text-overflow: ellipsis;
}

.seoBlock__btn {
    margin-top: 10px;
    background-color: #EC5E5A;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    width: 150px;
    border-radius: 5px;
}

.seoBlock__btn:hover {
    background-color: #bd4e4b;
}
