.continue__shopin_modal{
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.continue__shopin_modal__container{
    max-width: 400px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    background: #FFFFFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 0px 20px;
    border-radius: 15px;
}
.continue__shopin_modal__close__btn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}
.continue__shopin_modal__btn__row{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.continue__shopin_modal__btn{
    height: 40px;
    padding: 0 10px;
}