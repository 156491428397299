.categories_on_home_page{
    padding-top: 40px;
}

.categories_on_home_page__swiper__container{
    padding-top: 30px;
    font-size: 25px;
}
.categories_on_home_page__row__item{
    border: 1px solid #EC5E5A;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    color: black;
}
.categories_on_home_page__swiper__slide{
    width: auto!important;
}