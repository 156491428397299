.footer{
    padding: 60px 0 40px 0;
    background: #FFFFFF;
    box-shadow: rgba(50, 50, 93, 0.25) 4px 2px 5px 2px, rgba(0, 0, 0, 0.3) 4px 1px 3px 2px;
}

.footer__container{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.footer__row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}
.footer__row__item{
    min-width: 150px;
}

.footer__row__item__title{
    font-size: 30px;
}
.footer__row__item__links{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-left: 10px;
}

.footer__row__item__link{
    text-decoration: none;
    color: #0a0a0a;
    font-size: 20px;
    transition: all ease .5s;

}
.footer__row__item__link:hover{
    color: #EC5E5A;
    text-decoration: underline;
}
.footer__row__social{
    display: flex;
    column-gap: 50px;
    align-items: center;
    flex-wrap: wrap;
}
.footer__row__social__title{
    font-size: 20px;
}
.footer__row__social-box{
    display: flex;
    font-size: 30px;
    column-gap: 15px;
    align-items: center;
}
.footer__row__social-box svg{
    cursor: pointer;
    transition: all ease .5s;
}
.footer__row__social-box svg:hover{
    color: #EC5E5A;
}
@media screen and (max-width: 1100px){
    .footer{
        padding: 40px 0 100px 0;
    }

}
@media screen and (max-width: 600px){
    .footer__row__social{
        column-gap: 20px;
        row-gap: 15px;
    }
    .footer__row__item__title {
        font-size: 25px;
    }
    .footer__row__item__link{
        font-size: 16px;
    }
}
.footer__row__social-box__link{
    color: black;
}