
.product__page {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}
.product__page__container{
    width: 90%;
}
.whitebuybutton{
    border: 1px solid #EC5E5A;
    background-color: transparent;
    color: #EC5E5A
}
.whitebuybutton:hover{
    border: 1px solid #EC5E5A;
    background-color: #EC5E5A!important;
    color: white
}
.redbutton{
    border: 1px solid #fff;
    background-color: #EC5E5A;
    color: white;
}
.redbutton:hover{
    background-color: transparent;
    border: 1px solid #EC5E5A;
    color: #EC5E5A

}
.product_page_buy_buttons{
    width: 50%;
    padding: 15px;
    border-radius: 30px;
    text-align: center;
    font-size: 16px;
    transition: .5s;
}
.product__page__main {
    width: 100%;
    display: flex;
    column-gap: 40px;
    justify-content: space-evenly;
    background-color: #EC5E5A0D;
    padding: 25px 0;
}

.product__page__title {
    font-size: 36px;
    /*text-align: center;*/
    display: flex;
    column-gap: 10px;
    align-items: center;
    /*justify-content: center;*/
}
.product__page__main__info__rating{
    display: flex;
    column-gap: 4px;
    align-items: center;
}
.product__page__main__info__rating__count{
    display: flex;
    text-align: center;
    font-size: 15px;
    margin-left: 20px;
}
.product__page__main__info__rating_icon{
    cursor: pointer;
    width: 22px;
    height: 22px;
}

.product__page__main__info__rating_item.active svg path{
    fill:#FDBA74 ;
}
.product__page__main__slider-box {
    width: 50%;
    display: flex;
    position: relative;
    column-gap: 10px;
    height: 640px;
}

.product__page__main__info__card{
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px 20px 40px 0;
    width: 100%;
    margin: auto;
    position: relative;
}

.product__page__main__info {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}


.product__page__main__swiper {
    width: 80%;
}

.product__page__main__thumbs__swiper {
    width: 100px;
}

.product__page__main__thumbs__swiper-slide {
    height: 100px !important;
    width: 100px!important;
    display: flex;
    align-items: center;
}

.product__page__main__swiper-slide__img__box {
    width: 100%;
    min-height: 250px;
    background: #FFFFFF;
    height: 75vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

}

.product__page__main__swiper-slide__img__box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*.product__page__main__thumbs__swiper-slide {*/

/*    overflow: hidden;*/
/*}*/

.product__page__main__thumbs__swiper-slide__img-box {
    width: 100px;
    background: #FFFFFF;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product__page__main__thumbs__swiper-slide__img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media screen and (max-width: 1200px) {
    .product__page__container{
        width: 100%;
    }
    .product_page_buy_buttons{
        width: 90%;
    }
    .product__page__main {
        flex-direction: column;
        align-items: center;
        padding-top: 0;
    }

    .product__page__main__slider-box {
        width: 100%;
        flex-direction: column-reverse;
        height: auto;
    }

    .product__page__main__info {
        width: 90%;
    }

    .product__page__main__swiper {
        height: 480px;
        width: 100%;
    }

    .product__page__main__thumbs__swiper {
        height: 100px;
        padding-bottom: 20px;
    }

    /*.product__page__main__swiper-slide {*/
    /*    width:300px !important;*/
    /*}*/

    .product__page__main__swiper-slide__img__box {
        width: 100%;
        min-height: 250px;
        background: #FFFFFF;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .swiper-button-prev,
    .swiper-button-next{
        display: none;
    }
    .product__page__main__swiper-slide__img__box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .product__page__main__thumbs__swiper-slide {

        overflow: hidden;
    }

    .product__page__main__thumbs__swiper-slide__img-box {
        width: 100%;
        background: #FFFFFF;
        height: 100px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product__page__main__thumbs__swiper-slide__img-box img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

}
.you_could_like_to{
    width: 19%!important;
}
.you_could_like_to2{
    width: 15%!important;
}
@media screen and (max-width: 600px) {

    .product__page__container.container{
        padding: 0!important;
    }
    .product__page{
        padding: 0;
    }
    .product__page__main__swiper {
        height: 400px;
        width: 100%;
    }
    .product__page__title{
        font-size: 20px;
    }
    .product__page__main__info__availability__item{
        font-size: 16px;
    }
}
.swiper-button-prev,
.swiper-button-next{
    color: #EC5E5A!important;
}
.product__page__main__info__name {
    font-size: 50px;
    font-style: italic;
}
 @media (max-width: 768px) {
            .responsive-card {
              width: 47%!important;
            }
          }
.product__page__main__info__like__icon-box{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 50px;
    bottom: 15px;
    z-index: 2;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease .5s;
}
@media screen and (max-width: 600px){
    .product__page__main__info__like__icon-box{
        width: 40px;
        height: 41px;
        position: absolute;
        right: 5px;
        bottom: 5px;
    }
}
.product__page__main__info__like__icon {
    font-size: 30px;
    width: 30px;
    height: 30px;
    fill: black;
    transition: all ease .5s;
}
.product__page__main__info__like__icon-box:hover .product__page__main__info__like__icon path{
    fill: #EC5E5A;
}
.product__page__main__info__like__icon:hover {
    color: #EC5E5A;
    cursor: pointer;
}

.product__page__main__info__price-box {
    font-size: 28px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.product__page__main__info__price-box:has(.product__page__main__info__price__discount) .product__page__main__info__price {
    font-size: 25px;
    text-decoration: line-through;
}

.product__page__main__info__description {
    font-size: 25px;
    word-break: break-all;
    letter-spacing: 1px;
}

@media screen and (max-width: 400px) {
    .product__page__main__info__description {
        font-size: 18px;
    }
}

.product__page__main__info__item {
    display: flex;
    column-gap: 10px;
    align-items: center;

}
.product__page__main__info__item_category{
    display: none;
}
@media screen and (max-width: 600px) {

    .product__page__main__info__item_category{
        display: flex;
    }
}
.product__page__main__info__availability__item {
    display: flex;
    column-gap: 10px;
    align-items: center;
    font-size: 16px;
}

.it_is_available {
    color: #67C358;
}

.it_is_available svg {
    font-size: 23px;
}

.not_available {
    color: red;
}

.product__page__main__info__item__info {
    display: flex;
    column-gap: 5px;
}

.product__page__main__info__item__title {
    font-size: 20px;
    /*width: 200px;*/
}
@media screen and (max-width: 600px) {
    .product__page__main__info__item__title {
        font-size: 16px;
    }

    .product__page__main__info__card {
        padding: 0;
        width: 100%;
        padding-top: 40px;
    }
}
.product__page__main__info__choice_size {
    display: flex;
    column-gap: 20px;
    align-items: center;

}

.product__page__main__info__size {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;
    transition: all ease .5s;
    padding: 0 3px;
    background: #EC5E5A;
    border-radius: 5px;
}

.product__page__main__info__size.active {
    border: 1px solid #EC5E5A;
    background: #FFFFFF;
    color: black;
}

.product__page__main__info__size:hover {
    border: 1px solid #EC5E5A;
}

.product__page__main__info__item__quantity__change__btn:hover {
    color: #EC5E5A;
}

.product__page__main__info__item__quantity__change__btn {
    cursor: pointer;
}

.product__page__main__info__item__quantity {
    font-size: 30px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.product__page__main__info__item__quantity__input {
    height: 30px;
    width: 80px;
    font-size: 20px;
    text-indent: 5px;
}

.product__page__main__info__btn {
    height: 40px;
}

.product__page__benefits {
    width: 100%;
    padding-top: 40px;
}

.product__page__benefits__title {
    font-size: 40px;
}

.product__page__benefits__row {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    gap: 20px;
}

.product__page__benefits__row__item {
    width: 35%;
    min-width: 200px;
    border-radius: 10px;
    background: #FFFFFF;
    font-size: 20px;
    padding: 15px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

@media screen and (max-width: 780px) {
    .product__page__benefits__row__item {
        width: 90%;
    }

    .product__page__benefits__row {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.product__page__reviews {
    padding-top: 50px;
}

.product__page__reviews__title {
    font-size: 40px;
}

.product__page__reviews__row {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.product__page__review {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background: #FFFFFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px 20px;
    border-radius: 15px;
}

.product__page__review__author {
    font-size: 30px;
}

.product__page__review__date {
    font-size: 20px;
    color: #797777;
}

.product__page__review__text {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 25px;
    padding-left: 10px;
}

.product__page__review__form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding-top: 20px;
}

.product__page__review__form__input {
    width: 100%;
    height: 80px;
    border-radius: 15px;
    text-indent: 10px;
    padding-top: 10px;
    border: 1px solid #000000;
    font-size: 20px;
}

.product__page__review__form__btn {
    height: 40px;
    max-width: 300px;
}
@media screen and (max-width: 600px) {
    .product__page__review__form__btn{
        max-width: none;
    }
}
.product__page__additional {
    padding: 40px 0;
}
.additional__swiper__slide{
    padding: 10px 0 10px 10px;
}
.product__page__additional__title {
    font-size: 40px;
}
@media screen and (max-width: 600px){
    .product__page__benefits__title,
    .product__page__additional__title{
        font-size: 25px;
    }
    .product__page__additional,
    .similar__products,
    .product__page__review__form,
    .product__page__benefits,
    .product__page__reviews__title{
        width: 90%;
        margin:  0 auto;
    }
}
.product__page__additional__slider-box {

}

.product__page__quick__purchase {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 20;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    padding: 0 10px;
}

.product__page__quick__purchase__modal {
    position: relative;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    background: #FFFFFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 0px 20px;
    border-radius: 15px;
    height: 250px;
}

.product__page__quick__purchase__close__btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
}

.product__page__quick__purchase__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.product__page__quick__purchase__form__label {
    font-size: 20px;
}

.product__page__quick__purchase__form__input {
    width: 100%;
    height: 40px;
    border-radius: 15px;
    text-indent: 10px;
    font-size: 20px;
    margin-top: 15px;
}

.product__page__quick__purchase__form__btn {
    height: 40px;
}

.product__page__quick__purchase__title {
    font-size: 40px;

}

.product__page__additional__slider__card {
    width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    background: #FFFFFF;
    padding-bottom: 10px;
}

.product__page__additional__slider__card__img-box {
    width: 100%;
    background: #FFFFFF;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product__page__additional__slider__card__img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.product__page__additional__slider__card__btn {
    height: 30px;
    width: 90%;
}

.product__page .storefront__card {
    max-width: 250px;
}

.product__page .storefront__card__hidden__option-icon {
    font-size: 25px;

}

.personal__cabinet__message__text {
    text-align: center;
    font-size: 20px;
}
.product__page__modal__title{
    text-align: center;
    font-size: 25px;
}
.product__page__modal_btn-box {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
}

.product__page__main__info .faq{
    padding-top: 0;
}
.product__page__main__info .faq .page__navigation {
display: none;
}
.product__page__main__info .faq__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}
.faq__row{
    padding-bottom: 20px;
    row-gap: 10px;
}

.product__page__main__info .faq__title{
    display: none;
}

.product__page__main__info .faq__row__item__title{
    display: flex;
    align-items: center;
    font-size: 20px;
    transition: all ease .5s;
    font-style: italic;
    font-weight:300 ;
    border-bottom: 1px solid #000000;
    padding-bottom: 5px;
}

.product__page__main__info .faq__row__item{
    padding: 10px 0;
}
.product__page__main__info .faq__row__item__dropdown{
    padding: 10px 0 0 20px ;
    transition:  all ease.5s;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 1px;
}
@media screen and (max-width: 600px){
    .you_could_like_to{
        width: 46%!important;
    }
    .you_could_like_to2{
        width: 48%!important;
    }
    .product__page__main__info .faq__row__item__dropdown{
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 1px;
        padding:10px 0;
    }
}

@media screen and (max-width: 1100px) {
.product__page__main__thumbs__swiper{
    display: none;
}
    .product__page__main__info{
        padding-top: 30px;
    }
}
.product__page__categories{
    display: flex;
    column-gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    padding-left: 100px;
}
@media screen and (max-width: 700px){
    .product__page__categories{
        display: none;
    }
}
.product__page__categories::-webkit-scrollbar {
    display: none;
}
.product__page__categories__item {
    background: #FFFFFF;
    border: 1px solid #EC5E5A;
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 15px;
    transition: all ease .5s;
    cursor: pointer;
    color: black;
}
.product__page__categories__item__link{
    color: black;
}
.product__page__categories__item__link:hover{
    color: #EC5E5A;
}
.product__page__categories__item:hover {
    background: #EC5E5A;
    color: #FFFFFF;
}
/*similar__products slider*/
.similar__products__title{
    font-size: 40px;
}
.similar__products__slider-box{
    padding-top: 20px;
}
.product__page__review__form__item__error{
    color: red;
    font-size: 15px;
}
.personal__cabinet__message__btn {
    height: 40px;
}
