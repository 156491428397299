
.blog {
    padding: 50px 0;
}

.blog__title {
    font-size: 40px;
    text-align: center;
}

.blog__categories {
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding-top: 50px;
    overflow-x: auto;
    flex-wrap: wrap;
    row-gap: 10px;
}
.heart-icon {
  font-size: 24px;
  z-index: 0;
  border-radius: 50%;
}
.blog__categories::-webkit-scrollbar {
    display: none;
}

.blog__categories__item {
    background: #EC5E5A;
    border: 1px solid #EC5E5A;
    color: #FFFFFF;
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 15px;
    transition: all ease .5s;
    cursor: pointer;
}

.blog__categories__item:hover {
    background: #FFFFFF;
    color: #EC5E5A;
    border: 1px solid #EC5E5A;
}

.blog__row {
    padding-top: 50px;
    display: flex;

    justify-content: center;
    gap: 20px;
}

.blog__row__item {
    width: 30%;
    background: #FFFFFF;
    border: 1px solid #EC5E5A;
    padding: 10px 20px;
    position: relative;
    border-radius: 15px;
    transition: all ease .5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.blogHeadContainer{
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}
.blogHeadCard{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
}
.blogHeadCard .title{
    font-size: 24px;
    color: #fff;
}
.blogHeadCard .category{
    font-size: 16px;
    color: #EC5E5A;
}
.blogHeadCard .tag{
    font-size: 16px;
    color: #fff;
}
@media screen and (max-width: 943px) {
    .blog__row {
        flex-wrap: wrap;
    }

    .blog__row__item {
        width: 100%;
    }
}

.blog__row__item__hidden_card {
    border-radius: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(236, 94, 90, 0.2);
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog__row__item__author {
    font-size: 18px;
    color: gray;
}

.blog__row__item__date {
    font-size: 15px;
    color: gray;
}

.blog__row__item__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;

}

.blog__row__item__text {
    font-size: 18px;
}

.blog__row__item:hover .blog__row__item__hidden_card {
    z-index: 2;
    opacity: 1;
}

.blog__row__item__hidden_card_icon {
    font-size: 40px;
}

/*MASONRY_BLOG*/
.blog__row__masonry {
    padding-top: 50px;

}

/*.blog__row__masonry:nth-child(odd) div .blog__row__masonry__item:nth-child(odd) .blog__row__masonry__item__img__box {*/
/*    height: 250px; !* Стиль для непарних рядків та непарних елементів всередині *!*/
/*}*/

/*.blog__row__masonry:nth-child(odd) div .blog__row__masonry__item:nth-child(even) .blog__row__masonry__item__img__box {*/
/*    height: 450px; !* Стиль для непарних рядків та парних елементів всередині *!*/
/*}*/


.blog__row__masonry__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    background: #FFFFFF;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.blog__row__masonry__item__content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 0 10px 20px 10px;
}

.blog__row__masonry__item__img__box {
    width: 100%;
    overflow: hidden;

    display: flex;
    align-items: center;
}

.blog__row__masonry__item__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog__row__masonry__item__btn {
    width: 150px;
    height: 30px;
}


/*BLOG PAGE*/

.blog__page__hero {
    background:  url("../../assets/images/img_3678.jpg") no-repeat center center;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.blog__page__hero::after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.64);
    z-index: 1;
}
.blog__page__hero__title {
    font-size: 40px;
    color: #FFFFFF;
    z-index: 3;
}
.blog__page__container{
    padding: 40px 0;
}
.blog__page__date{
    font-size: 20px;
    color: gray;
}
.blog__page__text{
    font-size: 23px;
    line-height: 27px;

    padding-top: 30px;
}
.hr-with-text {
    display: flex;
    align-items: center;
    text-align: center;
}

.hr-with-text::before,
.hr-with-text::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #EC5E5A;
}

.hr-with-text::before {
    margin-right: 10px;
}

.hr-with-text::after {
    margin-left: 10px;
}
.category_name{
    font-size: 35px;
    font-weight: bold;
}
.admin_logo{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.show_more_btn{
    background-color: #EC5E5A;
    color: #fff;
    width: max-content;
    padding: 15px 50px;
    border-radius: 1.5em;
    font-weight: bold;
    margin-bottom: 50px;
}
.addEmailContainer{
    background-color: #EC5E5A;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 25px;
    align-items: center;
    flex-direction: column;
    position: absolute;
    gap: 15px;
    width: calc(100% - 50px);
}
.addEmailContainer input{
    background: #0101016B;
    font-size: 20px;
    padding: 10px 15px;
    width: 400px;
}
.addEmailContainer button{
    font-size: 20px;
    padding: 10px 15px;
    border: none;
}
.addressBtn{
    font-size: 20px;
    border: 1px solid #EC5E5A;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}
.addressBtn.active{
    background-color: #EC5E5A;
}
.infocard{
    border: 1px solid;
    padding: 25px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    border-radius: 5px;
}
.infocard .head{
    font-size: 25px;
    font-weight: bold;
}
.infocard .help{
    font-size: 20px;
}
.socialsvg{
    height: 30px;
}
.qaarticle{
    border: 1px solid;
    border-radius: 15px;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}
.leaveComment{
    padding: 20px;
    border: solid 1px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}
.leaveComment input{
    width: 100%;
    border-bottom: 1px solid;
    padding: 10px 0;
    font-size: 14px;
}
.leaveComment .btn{
    background-color:#EC5E5A;
    font-size:14px;
    padding:10px 25px;
    border-radius:15px;
    border: 1px solid;
    color: white;
    width: max-content;
}
.Artcontent{
    display: flex;
    justify-content: space-between;
    margin:40px 0;
}
.Artcontent .content{
    font-size: 20px;
    width: 65%;
}
.Artcontent .catTitle{
    font-size: 24px;
    width: 100%;
    border-bottom: 3px solid #EC5E5A;
}
.Artcontent .content ol{
    list-style: decimal;
}