.bottom__menu{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    background: #FFFFFF;
    z-index: 30;
    padding: 4px 5px 10px 0;
    box-shadow: rgba(50, 50, 93, 0.25) 4px 2px 5px 2px, rgba(0, 0, 0, 0.3) 4px 1px 3px 2px;
    display: none;

}

.bottom__menu__row{
    display: flex;
    justify-content: space-between;
}
.bottom__menu__row__item{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column;
    row-gap: 10px;
    font-weight: 300;
    color: black;
    text-decoration: none;
}

.bottom__menu__row__item.active{
    color: #EC5E5A;
}
.bottom__menu__row__item.active svg{
    fill: #EC5E5A;
}
.bottom__menu__row__item.active svg path{
    fill: #EC5E5A;
}
.bottom__menu__row__item svg{
    font-size: 30px;
    stroke-width: 0;
}
@media screen and (max-width: 1100px){
    .bottom__menu{
        display: block;
    }
}
@media screen and (max-width: 460px){
    .bottom__menu__row__item{
        font-size: 24px;
    }
    .bottom__menu__row__item{
        font-size: 15px;
    }
    .bottom__menu__row__item__text{

    }
    .bottom__menu__row__item svg{
        font-size: 25px;
        width: 25px;
        height: 25px;
        stroke-width: 0;
    }
    .bottom__menu__row__item__icon{
        width: 23px!important;
        height: 23px!important;
        margin-top: 2px;
    }
}
@media screen and (max-width: 355px){
    .bottom__menu__row__item{
        font-size: 18px;
    }
    .bottom__menu__row__item{
        font-size: 13px;
    }

}