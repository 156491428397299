.hero {
    position: relative;
    height: 75vh;

}
.hero__swiper {
    width: 100vw;
    height: 100%;

}
.hero__swiper__slide {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.hero__swiper__slide__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding: 0 10px 0 20px;
    color: #FFFFFF;
    position: relative;
    z-index: 100;
    align-items: center;
}
.hero__swiper__slide__content__title {
    font-size: 60px;
    letter-spacing: 2px;
    line-height: 70px;
    text-align: center;
    font-weight: 400;
}
.hero__swiper__slide__content__sub-title {
    font-size: 30px;
    letter-spacing: 2px;
    line-height: 30px;
    text-align: center;
    font-weight: 400;
}
.hero__swiper__custom__btn-box {
    display: flex;
    gap: 40px;
    align-items: center;
    position: absolute;
    top: 75%;
    left: 10%;
    z-index: 20;
}
.hero__swiper__custom__btn {
    background: #FFFFFF;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease .5s;
}
.hero__swiper__custom__btn svg {
    fill: #000000;
    font-size: 30px;
}
.custom-next svg {
    transform: rotateY(180deg);
}
.hero__swiper__custom__btn:hover {
    background: #EC5E5A;
}
.hero__swiper__custom__btn:hover svg {
    fill: #FFFFFF;
}
.hero__swiper__slide__content__btn {
    max-width: 400px;
    min-width: 250px;
    width: 100%;
    height: 50px;
    font-size: 25px;
}
@media screen and (max-width: 1100px ) {
    .hero__swiper__custom__btn-box {
        display: none;
    }
    .hero__swiper__slide__content__title {
        font-size: 40px;
        line-height: 45px;
    }
    .hero__swiper__slide__content {
        width: 95%;
        padding: 0;
    }
    .hero{
        height: 65vh;
    }
}
@media screen and (max-width: 600px){
    .hero__swiper__slide__content__title {
        font-size: 35px;
        line-height: 38px;
    }
    .hero__swiper__slide__content__sub-title {
        font-size: 20px;
        width: 95%;
        padding: 0;
    }
}
@media screen and (max-width: 400px){
    .hero__swiper__slide__content__title {
        font-size: 25px;
        line-height: 38px;
    }
    .hero__swiper__slide__content__sub-title {
        font-size: 18px;
        width: 95%;
        padding: 0;
    }
}