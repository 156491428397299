.reviews{
    padding-top: 80px;
}
.why_uscont{
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.reviews__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.reviews__swiper{
    width: 80%;
}
.reviews__swiper .swiper-wrapper{
    padding: 40px 0!important;

}
.reviews__swiper__container{
    width: 100%;
    position: relative;
    padding-bottom: 30px;
}
.reviews__swiper__slide{
    height: auto;
    border: 2px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 32%;
    background: white;
}

.reviews__swiper__slide__content{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    height: 100%;
    padding: 30px;
}
.review__swiper_created_at{
    color: #403f3f;
}
@media screen and (max-width: 500px){
    .reviews__swiper__slide__content{
        padding: 15px!important;
    }
    .reviews__swiper{
        width: 100%!important;
    }
    .reviews__swiper__slide_p{
        width: 48%!important;
    }
    .reviews__text-comment{
        font-size: 13px!important;
    }
    .reviews__author-name{
        font-size: 14px!important;
    }
    .reviews__swiper__slide__rating svg {
        width: 11px!important;
        height: 11px!important;
    }
    .review__swiper_created_at{
        font-size: 13px;
    }
}
.reviews__author-name{
    font-size: 30px;
    font-weight: bold;
}
.reviews__text-comment{
    font-size: 20px;
    line-height: 25px;
    color: #000000b0;
}
.reviews__swiper__slide__content__icon{
    fill: #FFFFFF;
    font-size: 70px;
}
.reviews__swiper__custom__btn-box{
    display: flex;
    column-gap: 20px;
    justify-content: center;
    padding-top: 20px;
}
.reviews__swiper__slide__rating{
    display: flex;
    column-gap: 5px;
}
.reviews__swiper__slide__rating svg {
    width: 20px;
    height: 20px;
}

.reviews__swiper__custom__btn{

    background: #EC5E5A;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 25px;
    transition: all ease .5s;
}
.reviews__swiper__custom__btn svg{
    fill: #FFFFFF;
}
.reviews__swiper__custom__btn:hover{
    background: #EC5E5A;
}
.reviews__swiper__custom__btn:hover svg{
    fill: #FFFFFF;
}
.reviews__header{
    display: flex;

    justify-content: space-between;
    width: 100%;
}
.reviews__header__btn{
    display: flex;
    align-items: center;
    gap: 10px;
    text-wrap: nowrap;
}
.reviews__header__btn svg {
    font-size: 25px;
    margin-top: 3px;
}

.blog_preview{
    width: 33%;
    margin-right: 20px;
    height: 280px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
